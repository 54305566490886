import { createRouter, createWebHistory } from 'vue-router'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      component: () => import('@/components/layout.vue'),
      children: [
        {
          path: '',
          name: 'index',
          component: () => import('@/views/batch_transfer.vue'),
          meta: { title: null },
        },
        {
          path: 'policy',
          name: 'policy',
          component: () => import('@/views/policy.vue'),
          meta: { title: '隐私政策' },
        },
        {
          path: 'service',
          name: 'service',
          component: () => import('@/views/service.vue'),
          meta: { title: '服务条款' },
        },
        {
          path: 'cookie',
          name: 'cookie',
          component: () => import('@/views/cookie.vue'),
          meta: { title: 'Cookies 协议' },
        },
        {
          path: 'faq',
          name: 'faq',
          component: () => import('@/views/faq.vue'),
          meta: { title: 'FAQ' },
        },
        {
          path: 'fee',
          name: 'fee',
          component: () => import('@/views/fee.vue'),
          meta: { title: '费用说明' },
        },
      ],
    },
  ],
})

router.beforeEach((to, from, next) => {
  window.document.title = to.meta?.title
    ? `Fire365.vip - ${to.meta.title}`
    : 'Fire365.vip - 最好的加密货币批量转账和代币空投工具'
  next()
})

export default router
